import { current, useAuthentication } from '@42.nl/authentication';
import { addSuccess } from '@42.nl/react-flash-messages';
import { Button, Icon, NavigationItem } from '@42.nl/ui';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Alert,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  NavbarBrand,
  Row
} from 'reactstrap';
import { toAbout } from '../../../app/About/links';
import { toCertificateCheckLogs } from '../../../app/CertificateLog/links';
import logo from '../../../app/img/logo.png';
import { toSchakelbord } from '../../../app/Schakelbord/links';
import { toUsersApp } from '../../../app/users/links';
import { stopImpersonation } from '../../../app/users/User';
import { toWhitelistDuplicateList } from '../../../app/WhitelistDuplicate/WhitelistDuplicateList/WhitelistDuplicateList';
import { CurrentUser, UserRole } from '../../../core/types';
import Logout from './Logout/Logout';

interface Props {
  title: string;
  children?: ReactNode;
}

export default function TopBar(props: Props) {
  const { title, children } = props;
  const { t } = useTranslation(['topbar', 'translation']);
  const authentication = useAuthentication<CurrentUser>();

  const [dropdownOpen, setDropDownOpen] = useState(false);

  function toggleDropdown() {
    setDropDownOpen(!dropdownOpen);
  }
  async function stopImpersonationUser(email: string) {
    await stopImpersonation();
    addSuccess({ text: t('translation:IMPERSONATE.STOP_SUCCESS', { email }) });
    current();
  }

  if (!authentication.isLoggedIn) {
    return null;
  }

  const currentUser = authentication.currentUser as CurrentUser;

  const impersonating = currentUser.switched;
  const email = currentUser.email;

  return (
    <>
      <Navbar
        color="primary"
        fixed="top"
        className="navbar-dark ease-in"
        expand
      >
        <NavbarBrand href="/">
          <img
            style={{ cursor: 'pointer' }}
            className="topbar-logo me-2"
            src={logo}
            alt="logo"
          />
        </NavbarBrand>
        <Nav navbar>
          <NavigationItem
            to={toCertificateCheckLogs()}
            icon="dashboard"
            text={t('TOPBAR.CERT_LOGS')}
          />
          <NavigationItem
            to={toSchakelbord()}
            show={
              currentUser.roles.includes(UserRole.ADMIN) ||
              currentUser.roles.includes(UserRole.ACCEPTATIETEAMLID)
            }
            icon="bar_chart"
            text={t('TOPBAR.SCHAKELBORD')}
          />
          <NavigationItem
            to={toWhitelistDuplicateList()}
            icon="bar_chart"
            text={t('TOPBAR.WHITELIST_CHECKER')}
          />
          <NavigationItem
            to={toUsersApp()}
            show={
              currentUser.roles.includes(UserRole.ADMIN) ||
              currentUser.roles.includes(UserRole.ACCEPTATIETEAMLID)
            }
            icon="people"
            text={t('TOPBAR.USERS')}
          />
        </Nav>
        <Nav className="ms-auto" navbar>
          <Dropdown nav isOpen={dropdownOpen} toggle={toggleDropdown}>
            <DropdownToggle nav caret color="outline-secondary">
              {email}
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem header>{t('translation:SETTINGS')}</DropdownItem>
              {impersonating ? (
                <DropdownItem
                  className="clickable"
                  tag="div"
                  color="link"
                  onClick={() => stopImpersonationUser(email)}
                >
                  {t('translation:IMPERSONATE.STOP')}
                </DropdownItem>
              ) : (
                <Logout />
              )}
            </DropdownMenu>
          </Dropdown>
          <NavigationItem to={toAbout()} icon="help" />
        </Nav>
      </Navbar>
      <div className="d-flex mt-4 mb-4">
        <h2 className="flex-fill align-self-center">{title}</h2>
        {children}
      </div>
      {impersonating ? (
        <Alert color="warning" open={true}>
          <Row>
            <Col className="d-flex align-items-center">
              <Icon icon="people" />
              <span className="ms-2">
                {t('translation:IMPERSONATE.MSG', { email })}
              </span>
            </Col>
            <Col>
              <Button
                className="float-end"
                color="primary"
                onClick={() => stopImpersonationUser(email)}
                icon="block"
              >
                {t('translation:IMPERSONATE.STOP')}
              </Button>
            </Col>
          </Row>
        </Alert>
      ) : null}
    </>
  );
}
