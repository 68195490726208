import { Input } from 'reactstrap';
import { Row } from '../types';

export interface SelectOptions<T> {
  onChange: (selection: T[]) => void;
  selection: T[];
  equals: (a: T, b: T) => boolean;
  maxSelectedItems?: number;
}

export interface HeaderProps<T> {
  content: T[];
  selectOptions: SelectOptions<T>;
}

// The funkyname is because of a bug in Webpack bundler
// Because there is already a function named Header, it will rename this to _Header
// But then it will try to export Header (which does not exists at point)
function SeederHeader<T>(props: HeaderProps<T>) {
  const { content, selectOptions } = props;

  // Do not render if no values are present or if there is a maximum of selectable items
  if (content.length === 0 || selectOptions.maxSelectedItems !== undefined) {
    return null;
  }

  // Is checked when every item in the content is selected.
  const checked = content.every(c =>
    selectOptions.selection.some(v => selectOptions.equals(c, v))
  );

  return (
    <Input
      className="data-table-checkbox data-table-checkbox--header"
      type="checkbox"
      checked={checked}
      onChange={() => {
        let selection;
        if (checked) {
          // All items in the content should be unselected.
          selection = selectOptions.selection.filter(
            v => !content.some(c => selectOptions.equals(c, v))
          );
        } else {
          // All items in the content that have not been selected become selected
          const items = content.filter(
            c => !selectOptions.selection.some(v => selectOptions.equals(c, v))
          );
          selection = [...selectOptions.selection, ...items];
        }

        selectOptions.onChange(selection);
      }}
    />
  );
}

export interface CellProps<T> {
  original: T;
  selectOptions: SelectOptions<T>;
}

function SeederCell<T>(props: CellProps<T>) {
  const { original, selectOptions } = props;

  // Is this item currenty in the selection.
  const checked = selectOptions.selection.some(entity =>
    selectOptions.equals(original, entity)
  );

  return (
    <Input
      className="data-table-checkbox"
      type="checkbox"
      checked={checked}
      disabled={!checked && !isSelectable(selectOptions)}
      onChange={() => {
        let selection;
        if (checked) {
          // Remove the item from the selection when removed
          selection = selectOptions.selection.filter(
            entity => !selectOptions.equals(entity, original)
          );
        } else if (isSelectable(selectOptions)) {
          // Add the item to the selection when added.
          selection = [...selectOptions.selection, original];
        } else {
          return;
        }

        selectOptions.onChange(selection);
      }}
    />
  );
}

function isSelectable<T>(selectOptions: SelectOptions<T>) {
  return (
    selectOptions.maxSelectedItems === undefined ||
    selectOptions.selection.length < selectOptions.maxSelectedItems
  );
}

/**
 * Creates a column which shows a checkbox in the header and the cells.
 *
 * When the checkbox is checked the entire 'entity' T representing that row
 * is added to the selection.
 *
 * In the header for that row a check all checkbox is rendered. It is checked
 * when all items on the Page are currently selected. When it is not
 * checked, clicking the checkbox results in the entire content T array
 * to be added to the selection. If it is checked, clicking the checkbox
 * results in the entire content T array to be removed from the selection.
 *
 * This means that the "check all checkbox" selects / de-selects what
 * the user currently sees, it does not mean: select all entities from
 * from the entire database.
 *
 * The reason `options.equals` must be provided is because even though
 * switching back and forth between a page is likely to fetch the
 * same entities. In JavaScript they will be different references.
 * So we cannot check for reference equality, thus the `equals` method
 * is needed.
 *
 * @export
 * @template T
 * @param {T[]} content The content of the current Page
 * @param {(selection: T[]) => void} selectOptions.onChange Callback which provides the new selection when selection changes
 * @param {T[]} selectOptions.selection The current selected entities of type T
 * @param {(a: T, b: T) => boolean} selectOptions.equals A function to check for equality between two entities.
 * @returns
 */
export default function makeSelectionColumn<T>(
  content: T[],
  selectOptions: SelectOptions<T>
) {
  return {
    id: 'DATATABLE_SELECTION_COLUMN',
    accessor: false,
    sortable: false,
    resizable: false,
    filterable: false,
    maxWidth: 50,
    Header: () => (
      <SeederHeader content={content} selectOptions={selectOptions} />
    ),
    Cell: (data: Row<T>) => (
      <SeederCell original={data.original} selectOptions={selectOptions} />
    )
  };
}

export { SeederHeader as Header, SeederCell as Cell };
