import { get, Page, post, put } from '@42.nl/spring-connect';
import { UserRole } from '../../core/types';
import { UserListQueryParams } from './UserList/UserList';

const USERS_URL = '/api/users';

type Params = UserListQueryParams & {
  size?: number;
};

export type UserResult = {
  id: number;
  email: string;
  active: boolean;
  roles: UserRole[];
};

export type UserFormData = {
  id?: number;
  email: string;
  password: string;
  active: boolean;
  roles: UserRole[];
};

export function searchUsers(
  queryParams: Partial<Params>
): Promise<Page<UserResult>> {
  return get(USERS_URL, queryParams);
}

export function findUser(id: number | string): Promise<UserResult> {
  return get(`${USERS_URL}/${id}`);
}

export function createUser(props: UserFormData): Promise<UserResult> {
  return post<UserResult>(`${USERS_URL}`, props);
}

export function updateUser(user: UserFormData): Promise<UserResult> {
  return put<UserResult>(`${USERS_URL}/${user.id}`, user);
}

export function toggleUserActivation({
  id,
  active
}: {
  id: number;
  active: boolean;
}): Promise<UserResult> {
  return put<UserResult>(`${USERS_URL}/${id}/active`, { active: !active });
}

export function updateUserPassword({
  id,
  password
}: {
  id: number | string;
  password: string;
}): Promise<UserResult> {
  return put<UserResult>(`${USERS_URL}/${id}/password`, { password });
}

export function impersonateUser(email: string): Promise<void> {
  // Note the username has to be a query param here for some mystical
  // reason, otherwise it will not work.
  return post<void>(`/api/login/impersonate?username=${email}`, {});
}

export function stopImpersonation(): Promise<void> {
  return post<void>(`/api/logout/impersonate`, {});
}
