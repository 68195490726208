import { setTranslator } from '@42.nl/ui';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
// Load moment js locales
import 'moment/locale/nl';
import { initReactI18next } from 'react-i18next';

i18n
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next) // if not using I18nextProvider
  .init({
    fallbackLng: 'nl',
    debug: process.env.NODE_ENV === 'development',
    interpolation: {
      escapeValue: false // not needed for react!!
    },
    supportedLngs: ['nl', 'en'],
    ns: ['42-ui', 'validation'],
    // react i18next special options (optional)
    react: {
      bindI18n: 'languageChanged loaded',
      bindI18nStore: 'added removed',
      nsMode: 'default',
      useSuspense: true
    }
  });

/**
 * Translation provider for @42.nl/ui, which maps on the namespace `42-ui`.
 * @see public/locales/en/42-ui.json
 */
setTranslator(({ key, data }) => i18n.t(`42-ui:${key}`, data));

export default i18n;
