import { ReactNode } from 'react';
import { Col, Container, Row } from 'reactstrap';
import EnvBanner from '../EnvBanner/EnvBanner';
import TopBar from './TopBar/TopBar';

interface Props {
  children: ReactNode;
  breadcrumbs?: ReactNode;
  title: string;
}

export default function AppFrame(props: Props) {
  const { title, breadcrumbs, children } = props;

  return (
    <div className="AppFrame">
      <EnvBanner />
      <Container fluid>
        <Row>
          <Col md={12} lg={12} className="pt-0 ms-md-auto ms-lg-auto">
            <TopBar title={title}>{breadcrumbs}</TopBar>
            {children}
          </Col>
        </Row>
      </Container>
    </div>
  );
}
