/* istanbul ignore file */

/**
 * We make istanbul ignore this file because the lazy imports are not
 * tested. The actual  components themselves are already being tested
 * in their own file, and we do not want to test React's lazy itself.
 */

import { AuthorizedRoute, PrivateRoute } from '@42.nl/authentication';
import { lazy } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { CurrentUser, UserRole } from '../../core/types';
import { ABOUT_URL } from '../About/links';
import { CERTIFICATE_CHECK_LOGS_URL } from '../CertificateLog/links';
import { LOGIN_URL } from '../Login/links';
import { NODE_APP_URL } from '../Node/links';
import { SCHAKELBORD_URL } from '../Schakelbord/links';
import { USER_APP_URL } from '../users/links';
import { WHITELIST_DUPLICATE_LIST_URL } from '../WhitelistDuplicate/WhitelistDuplicateList/WhitelistDuplicateList';

/* istanbul ignore next */
const About = lazy(
  () => import(/* webpackChunkName: "about" */ '../About/About')
);

/* istanbul ignore next */
const Login = lazy(
  () => import(/* webpackChunkName: "login" */ '../Login/Login')
);

/* istanbul ignore next */
const NotFound = lazy(
  () => import(/* webpackChunkName: "notFound" */ './NotFound/NotFound')
);

/* istanbul ignore next */
const UserApp = lazy(
  () => import(/* webpackChunkName: "users" */ '../users/UserApp')
);

/* istanbul ignore next */
const CertificateCheckLogList = lazy(
  () =>
    import(
      /* webpackChunkName: "users" */ '../CertificateLog/CertificateCheckLogApp'
    )
);

/* istanbul ignore next */
const Schakelbord = lazy(
  () => import(/* webpackChunkName: "dashboard" */ '../Schakelbord/Schakelbord')
);

/* istanbul ignore next */
const NodeApp = lazy(
  () => import(/* webpackChunkName: "nodes" */ '../Node/NodeApp')
);

/* istanbul ignore next */
const WhitelistDuplicateList = lazy(
  () =>
    import(
      /* webpackChunkName: "nodes" */ '../WhitelistDuplicate/WhitelistDuplicateList/WhitelistDuplicateList'
    )
);

export default function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <AuthorizedRoute<CurrentUser>
          authorizer={user =>
            !!(
              user?.roles.includes(UserRole.ADMIN) ||
              user?.roles.includes(UserRole.ACCEPTATIETEAMLID)
            )
          }
          path={SCHAKELBORD_URL}
        >
          <Schakelbord />
        </AuthorizedRoute>

        <PrivateRoute exact path={CERTIFICATE_CHECK_LOGS_URL}>
          <CertificateCheckLogList />
        </PrivateRoute>

        <PrivateRoute exact path={WHITELIST_DUPLICATE_LIST_URL}>
          <WhitelistDuplicateList />
        </PrivateRoute>

        <AuthorizedRoute<CurrentUser>
          authorizer={user =>
            !!(
              user?.roles.includes(UserRole.ADMIN) ||
              user?.roles.includes(UserRole.ACCEPTATIETEAMLID)
            )
          }
          path={USER_APP_URL}
        >
          <UserApp />
        </AuthorizedRoute>

        <AuthorizedRoute<CurrentUser>
          path={NODE_APP_URL}
          authorizer={user => !!user?.roles.includes(UserRole.ADMIN)}
        >
          <NodeApp />
        </AuthorizedRoute>

        <PrivateRoute exact path={ABOUT_URL}>
          <About />
        </PrivateRoute>

        <Route path={LOGIN_URL}>
          <Login />
        </Route>

        <Route>
          <NotFound />
        </Route>
      </Switch>
    </BrowserRouter>
  );
}
