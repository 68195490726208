import { logout, useIsLoggedIn } from '@42.nl/authentication';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import { DropdownItem } from 'reactstrap';

export default function Logout() {
  const { t } = useTranslation('login');
  const isLoggedIn = useIsLoggedIn();

  async function onLogout() {
    await logout();
    window.location.reload();
  }

  if (isLoggedIn === false) {
    return <Redirect to="/" />;
  }

  return (
    <DropdownItem
      className="clickable"
      tag="div"
      color="link"
      onClick={onLogout}
    >
      {t('LOGIN.LOGOUT')}
    </DropdownItem>
  );
}
