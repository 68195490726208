import { get } from '@42.nl/spring-connect';
import { useQuery } from '@tanstack/react-query';

export interface Environment {
  name: string;
  alert: boolean;
}

export async function loadEnvironment(): Promise<Environment> {
  return await get('/api/actuator/environment');
}

export default function EnvBanner() {
  const { data } = useQuery(['loadEnvironment'], loadEnvironment);

  if (!data) {
    return null;
  }

  const { name, alert } = data;

  if (!alert) {
    return null;
  }

  return (
    <div className="EnvBanner">
      <span>{name}</span>
    </div>
  );
}
