import { Page } from '@42.nl/spring-connect';
import { Pagination } from '@42.nl/ui';
import classNames from 'classnames';
import { debounce, find, isEqual } from 'lodash';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import ReactTable, { Column as ReactTableColumn } from 'react-table';
import EmptyTable from '../EmptyTable/EmptyTable';
import makeSelectionColumn, {
  SelectOptions
} from '../SelectionColumn/SelectionColumn';
import { OnRowClickCallback, QueryParams, ReactTableState } from '../types';
import {
  getDefaultFiltered,
  getDefaultSorted,
  getSort,
  makeOnRowClick
} from '../utils';

export interface Column<Entity> extends ReactTableColumn<Entity> {
  id: string;
  accessor: (e: Entity) => ReactNode;
}

interface Props<Entity, QP extends QueryParams> {
  queryParams: QP;
  columns: Column<Entity>[];
  page: Page<Entity>;
  pageSize: number;
  loading: boolean;
  emptyMessage: string;
  fetchPage: (queryParams: QP) => void;
  onRowClick?: OnRowClickCallback<Entity>;
  select?: SelectOptions<Entity>;
}

export default function DataTableMultiSearch<Entity, QP extends QueryParams>(
  props: Props<Entity, QP>
) {
  let { columns } = props;
  const {
    page,
    pageSize,
    queryParams,
    loading,
    emptyMessage,
    select,
    fetchPage,
    onRowClick
  } = props;
  const { t } = useTranslation();

  const debouncedFetchPage = debounce((queryParams: QP) => {
    fetchPage(queryParams);
  }, 500);

  const defaultFiltered = getDefaultFiltered(columns, queryParams);
  const defaultSorted = getDefaultSorted(queryParams.sort);

  function onSortOrFilterChanged(reactTableState: ReactTableState) {
    const prevQueryParams = props.queryParams;

    const queryParams: QP = {
      ...prevQueryParams,
      sort: getSort(reactTableState.sorted)
    };

    // Apply the filters to the query params
    columns.forEach(column => {
      if (!column.filterable) {
        return;
      }

      const filter = find(reactTableState.filtered, { id: column.id });
      // @ts-ignore
      queryParams[column.id] = filter ? filter.value : '';
    });

    /* 
      Do not bother doing anything when the query params have not changed.

      This blocks the first call to `sortOrFilterChanged` which react-table 
      makes on its componentDidMount. We do this because we expect the 
      user of this component to provide the initial data to us. If we 
      allowed the call to go through we would have the same two request 
      running at the same time.
    */
    if (isEqual(queryParams, prevQueryParams)) {
      return;
    }

    // Change straight away when sort changes.
    if (queryParams.sort !== prevQueryParams.sort) {
      fetchPage(queryParams);
    } else {
      // But wait until the user stops typing when search input changes
      debouncedFetchPage(queryParams);
    }
  }

  function onPageChanged(page: number) {
    fetchPage({ ...queryParams, page });
  }

  if (select) {
    const selectionColumn = makeSelectionColumn(page.content, select);
    // @ts-ignore
    columns = [selectionColumn, ...columns];
  }

  return (
    <ReactTable
      manual={true}
      filterable={true}
      multiSort={false}
      minRows={pageSize}
      className={classNames({
        '-striped -hightlight': page.content.length > 0
      })}
      showPagination={page.totalPages > 1}
      PaginationComponent={() => (
        <div className="-pagination justify-content-center pt-3">
          <Pagination page={page} onChange={onPageChanged} />
        </div>
      )}
      data={page.content}
      loading={loading}
      columns={columns}
      defaultPageSize={pageSize}
      onFetchData={onSortOrFilterChanged}
      defaultFiltered={defaultFiltered}
      defaultSorted={defaultSorted}
      showPageSizeOptions={false}
      getTrProps={makeOnRowClick(onRowClick)}
      noDataText={
        <EmptyTable
          title={emptyMessage}
          hasFiltered={defaultFiltered.length > 0}
        />
      }
      previousText={t('PAGINATION.PREVIOUS')}
      nextText={t('PAGINATION.NEXT')}
      loadingText={t('PAGINATION.LOADING')}
      pageText={t('PAGINATION.PAGE')}
      ofText={t('PAGINATION.OF')}
      rowsText={t('PAGINATION.ROWS')}
    />
  );
}
