import { Url, urlBuilder, useQueryParams } from '@42.nl/react-url';
import { emptyPage } from '@42.nl/spring-connect';
import { Icon } from '@42.nl/ui';
import { useQuery } from '@tanstack/react-query';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardBody,
  UncontrolledTooltip
} from 'reactstrap';
import { AppFrame, DataTableMultiSearch } from '../../../ui';
import { textFilter } from '../../../ui/tables/filters';
import {
  searchWhitelistDuplicates,
  WhitelistDuplicateResult
} from '../WhitelistDuplicate';

export type WhitelistDuplicateListQueryParams = {
  page: number;
  server?: string;
  sort: string;
};

export function defaultWhitelistDuplicateListQueryParams(): WhitelistDuplicateListQueryParams {
  return {
    page: 1,
    server: '',
    sort: 'aangemaakt,DESC'
  };
}

const PAGE_SIZE = 25;

async function loadData({
  queryParams
}: {
  queryParams: WhitelistDuplicateListQueryParams;
}) {
  return searchWhitelistDuplicates({
    ...queryParams,
    size: PAGE_SIZE
  });
}

export default function WhitelistDuplicateList() {
  const location = useLocation();
  const history = useHistory();
  const queryParams = useQueryParams({
    location,
    defaultQueryParams: defaultWhitelistDuplicateListQueryParams(),
    debugName: 'WhitelistDuplicateList'
  });

  const { t } = useTranslation('whitelistduplicate');

  const { isLoading, data = emptyPage<WhitelistDuplicateResult>() } = useQuery(
    ['loadWhitelistDuplicates', queryParams],
    () => loadData({ queryParams })
  );

  function queryParamsChanged(queryParams: WhitelistDuplicateListQueryParams) {
    history.replace(toWhitelistDuplicateList(queryParams));
  }

  return (
    <AppFrame
      title={t('DUPLICATE_WHITELIST')}
      breadcrumbs={
        <Breadcrumb className="ms-auto">
          <BreadcrumbItem active>{t('DUPLICATE_WHITELIST')}</BreadcrumbItem>
        </Breadcrumb>
      }
    >
      <Card>
        <CardBody>
          <DataTableMultiSearch
            queryParams={queryParams}
            page={data}
            columns={[
              {
                id: 'hostname',
                Header: t('COLUMNS.HOSTNAME'),
                accessor: a => a.hostname,
                filterable: true,
                Filter: textFilter()
              },
              {
                id: 'server',
                Header: t('COLUMNS.SERVER'),
                accessor: a => a.server,
                filterable: true,
                Filter: textFilter()
              },
              {
                id: 'url',
                Header: t('COLUMNS.URL'),
                accessor: a => a.server,
                Cell: ({
                  original
                }: {
                  original: WhitelistDuplicateResult;
                }) => {
                  const a = original;
                  return (
                    <>
                      <span id={`url-${a.id}`}>{a.url}</span>
                      <UncontrolledTooltip
                        placement="left"
                        target={`url-${a.id}`}
                      >
                        {a.url}
                      </UncontrolledTooltip>
                    </>
                  );
                },
                filterable: false
              },
              {
                id: 'opgelost',
                Header: t('COLUMNS.OPGELOST'),
                accessor: (a: WhitelistDuplicateResult) => {
                  return a.opgelost ? (
                    <Icon icon="check" />
                  ) : (
                    <Icon icon="close" />
                  );
                },
                filterable: false,
                sortable: true
              },
              {
                id: 'aangemaakt',
                Header: t('COLUMNS.AANGEMAAKT'),
                accessor: (a: WhitelistDuplicateResult) => {
                  const time = moment(a.aangemaakt).format(
                    'DD-MM-YYYY HH:mm:ss'
                  );
                  return <span title={time}>{time}</span>;
                },
                filterable: false
              }
            ]}
            pageSize={PAGE_SIZE}
            loading={isLoading}
            emptyMessage={t('NOT_FOUND')}
            fetchPage={(queryParams: WhitelistDuplicateListQueryParams) =>
              queryParamsChanged(queryParams)
            }
          />
        </CardBody>
      </Card>
    </AppFrame>
  );
}

export const WHITELIST_DUPLICATE_LIST_URL = '/whitelist-duplicates';

export function toWhitelistDuplicateList(
  queryParams?: Partial<WhitelistDuplicateListQueryParams>
): Url {
  return urlBuilder({
    url: WHITELIST_DUPLICATE_LIST_URL,
    queryParams,
    defaultQueryParams: defaultWhitelistDuplicateListQueryParams()
  });
}
