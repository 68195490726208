export enum UserRole {
  ADMIN = 'TECHNISCH_BEHEERDER',
  USER = 'USER',
  ACCEPTATIETEAMLID = 'ACCEPTATIETEAM_LID',
  FUNCTIONEELBEHEERDER = 'FUNCTIONEEL_BEHEERDER'
}

/*
  Represents a user which is currently logged-in.
*/
export interface CurrentUser {
  id: number;
  email: string;
  active: boolean;
  roles: UserRole[];
  switched: boolean; // Whether an ADMIN is impersonating the user.
}

export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type Diff<T, U> = T extends U ? never : T;

export type UnionKeys<T> = T extends any ? keyof T : never;
export type DistributiveOmit<T, K extends UnionKeys<T>> = T extends any
  ? Omit<T, Extract<keyof T, K>>
  : never;
