import { Icon } from '@42.nl/ui';
import { capitalize } from 'lodash';
import { ReactElement } from 'react';
import { Input, InputGroup, InputGroupText } from 'reactstrap';
import { FilterOptions } from '../types';

interface SelectFilterConfig {
  allOption: string;
  options: { value: string; label: string }[];
}

export function selectFilter(
  config: SelectFilterConfig
): (options: FilterOptions) => ReactElement {
  return function ({ filter, onChange }: FilterOptions) {
    return (
      <Input
        type="select"
        name="select"
        onChange={event => onChange(event.target.value)}
        value={filter ? filter.value : ''}
      >
        <option value="">{config.allOption}</option>
        {config.options.map(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </Input>
    );
  };
}

interface EnumFilterConfig {
  allOption: string;
  values: string[];
}

export function enumFilter(
  config: EnumFilterConfig
): (options: FilterOptions) => ReactElement {
  const options = config.values.map(value => ({
    value,
    label: capitalize(value)
  }));

  return selectFilter({ options, allOption: config.allOption });
}

export function textFilter(): (options: FilterOptions) => ReactElement {
  return function ({ filter, onChange }: FilterOptions) {
    return (
      <InputGroup>
        <InputGroupText addonType="prepend">
          <Icon icon="search" />
        </InputGroupText>
        <Input
          type="text"
          name="search"
          placeholder="Search"
          id="search"
          onChange={event => onChange(event.target.value)}
          value={filter ? filter.value : ''}
        />
      </InputGroup>
    );
  };
}
