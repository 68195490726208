import { get, Page } from '@42.nl/spring-connect';
import { WhitelistDuplicateListQueryParams } from './WhitelistDuplicateList/WhitelistDuplicateList';

const WHITELIST_DUPLICATE_URL = '/api/whitelist-duplicates';

type Params = WhitelistDuplicateListQueryParams & {
  size?: number;
};

export type WhitelistDuplicateResult = {
  id: number;
  hostname: string;
  server: string;
  url: string;
  opgelost: boolean;
  aangemaakt: Date;
};

export function searchWhitelistDuplicates(
  queryParams: Partial<Params>
): Promise<Page<WhitelistDuplicateResult>> {
  return get(WHITELIST_DUPLICATE_URL, queryParams);
}
