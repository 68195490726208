import {
  AuthenticationProvider,
  authInterceptor,
  configureAuthentication,
  getXSRFToken
} from '@42.nl/authentication';
import { configureConstraint, loadConstraints } from '@42.nl/jarb-final-form';
import { configureEnums, EnumsProvider } from '@42.nl/react-spring-enums';
import { LoadingPage } from '@42.nl/ui';
import axios from 'axios';
import { Suspense } from 'react';
import { render } from 'react-dom';
// Configure the progress bar to automatically trigger on fetch.
import { configureApi } from '@42.nl/spring-connect';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ProgressBar } from 'react-fetch-progressbar';
import { I18nextProvider } from 'react-i18next';
import 'react-table/react-table.css';
import { toCertificateCheckLogs } from './app/CertificateLog/links';
import { toLogin } from './app/Login/links';
import Routes from './app/Routes/Routes';
import { validation } from './core/validation/validation';
import handleErrors from './error';
import i18n from './i18n';
import * as serviceWorker from './registerServiceWorker';
import './styles/main.scss';
import FlashMessages from './ui/FlashMessages/FlashMessages';

const api = axios.create();
api.defaults.headers.common['X-XSRF-TOKEN'] = getXSRFToken();
api.defaults.withCredentials = true;
api.interceptors.response.use(undefined, authInterceptor);
api.interceptors.response.use(undefined, validation);
configureApi(api);

configureAuthentication({
  authenticationUrl: '/api/authentication',
  currentUserUrl: '/api/authentication/current',
  loginRoute: toLogin(),
  dashboardRoute: toCertificateCheckLogs()
});

configureConstraint({
  constraintsUrl: '/api/constraints'
});

configureEnums({
  enumsUrl: '/api/enums'
});

handleErrors();
loadConstraints();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      cacheTime: 0
    }
  }
});

const rootElement = document.getElementById('root');

if (rootElement) {
  render(
    <Suspense fallback={<LoadingPage />}>
      <QueryClientProvider client={queryClient}>
        <AuthenticationProvider>
          <EnumsProvider>
            <I18nextProvider i18n={i18n}>
              <ProgressBar />
              <FlashMessages />
              <Routes />
            </I18nextProvider>
          </EnumsProvider>
        </AuthenticationProvider>
      </QueryClientProvider>
    </Suspense>,
    rootElement
  );

  if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept();
  }
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
