import { Url, urlBuilder } from '@42.nl/react-url';
import { SchakelbordQueryParams } from './Schakelbord';

// Used to fill in the blanks when useQueryParams is leeg.
export function defaultSchakelbordQueryParams(): SchakelbordQueryParams {
  return {
    page: 1
  };
}

export const SCHAKELBORD_URL = '/schakelbord';

// Een url functie die een query accepteert.
export function toSchakelbord(
  queryParams?: Partial<SchakelbordQueryParams>
): Url {
  return urlBuilder({
    url: SCHAKELBORD_URL,
    queryParams,
    defaultQueryParams: defaultSchakelbordQueryParams()
  });
}
