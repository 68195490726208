import { setErrors } from '@42.nl/react-error-store';
import { addError } from '@42.nl/react-flash-messages';
import { get } from 'lodash';
import i18n from '../../i18n';

export function validation(error: any): Promise<any> {
  if (typeof error === 'undefined' || typeof error.response === 'undefined') {
    return Promise.reject(error);
  }

  if (error.response.status === 403) {
    const globalErrors = get(error.response.data, 'globalErrors', []);
    /**
     * This is included so the user doesn't get a double error message when
     * incorrectly entering their password in ChangePassword
     * */
    if (globalErrors.includes('CLIENT.INCORRECT_PASSWORD')) {
      return Promise.reject(error);
    }
    addError({ text: 'You are not authorized to perform this action.' });
    return Promise.reject(error);
  }

  if (error.response.status === 422) {
    const globalErrors = get(error.response.data, 'globalErrors', []);

    globalErrors.forEach((message: any) =>
      addError({
        text: `An error occurred: ${i18n.t(
          `global-errors:${message}`,
          message
        )}`
      })
    );

    const fieldErrors = get(error.response.data, 'fieldErrors', {});
    setErrors(fieldErrors);
  }

  // Keep the chain alive.
  return Promise.reject(error);
}
