import { Sort, OnRowClickCallback, Filter, QueryParams } from './types';
import { ComponentPropsGetterR, RowInfo } from 'react-table';

export function getSort(sorted: Sort[]): string {
  if (sorted.length === 0) {
    return '';
  }

  const name = sorted[0].id;
  const direction = sorted[0].desc ? 'DESC' : 'ASC';

  return `${name},${direction}`;
}

export function getDefaultFiltered<T extends { id: string }>(
  columns: T[],
  queryParams: QueryParams
): Filter[] {
  return columns
    .map(column => ({
      id: column.id,
      value: queryParams[column.id]
    }))
    .filter(({ value }) => value !== undefined && value !== '');
}

export function getDefaultSorted(sort: string): Sort[] {
  if (!sort) {
    return [];
  } else {
    const [id, direction] = sort.split(',');

    return [{ id, desc: direction === 'DESC' }];
  }
}

export function makeOnRowClick<T>(
  onRowClick?: OnRowClickCallback<T>
): ComponentPropsGetterR {
  return function(_: any, rowInfo?: RowInfo) {
    return {
      style: {
        cursor: onRowClick ? 'pointer' : 'default'
      },
      onClick: () => {
        if (onRowClick && rowInfo) {
          onRowClick(rowInfo.row._original);
        }
      }
    };
  };
}
