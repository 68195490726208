import { ContentState } from '@42.nl/ui';
import { useTranslation } from 'react-i18next';

interface Props {
  hasFiltered: boolean;
  title: string;
}

// TODO: Remove with the introduction of an empty state for ContentState
export default function EmptyTable(props: Props) {
  const { hasFiltered, title } = props;
  const { t } = useTranslation();

  return (
    <ContentState
      mode={hasFiltered ? 'no-results' : 'empty'}
      title={title}
      subTitle={hasFiltered ? t('42-ui:EmptyModal.NO_RESULTS.SUBTITLE') : ''}
    />
  );
}
